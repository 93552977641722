import { useEffect, useState } from "react";
import axios from "axios";
import dotenv from "dotenv";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import "../../styles/Gallery.css";
dotenv.config();

export default function GalleriesList() {
  if (document.body.style !== "") document.body.style = "";
  const { REACT_APP_REST_URI, REACT_APP_IMAGES_PATH, PUBLIC_URL } = process.env;
  const [categoriesData, setCategoriesData] = useState([]);
  const [typesData, setTypesData] = useState([]);
  const { type } = useParams();

  useEffect(() => {
    const source = axios.CancelToken.source();
    axios
      .get(`${REACT_APP_REST_URI}categories${type ? `/${type}` : ""}`, {
        cancelToken: source.token,
      })
      .then(({ data }) => {
        setCategoriesData(data.categoriesData);
        setTypesData(data.typesData || []);
      })
      .catch(() => {
        setCategoriesData([]);
        setTypesData([]);
      });
    return () => source.cancel();
  }, [REACT_APP_REST_URI, type]);

  return (
    <div>
      <h1>RODZAJE NAGROBKÓW</h1>
      <div className="gallery">
        {categoriesData.map((data) => (
          <Link
            to={`/kategorie/${
              type || "standard"
            }/${data.categoryPath.toLowerCase()}`}
            key={data.category}
          >
            <div>
              <img
                className="galleryImage"
                src={`${PUBLIC_URL}${REACT_APP_IMAGES_PATH}${data.image}`}
                alt={data.category}
              />
              <h2>{data.category.toUpperCase()}</h2>
            </div>
          </Link>
        ))}
        {typesData.map((data) => (
          <Link to={`/kategorie/${data.type.toLowerCase()}`} key={data.type}>
            <div>
              <img
                className="galleryImage"
                src={`${PUBLIC_URL}${REACT_APP_IMAGES_PATH}${data.image}`}
                alt={data.type}
              />
              <h2>{data.type.toUpperCase()}</h2>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}
