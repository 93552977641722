import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import "../../styles/Gallery.css";

function GalleryList() {
  if (document.body.style !== "") document.body.style = "";
  const { REACT_APP_REST_URI, PUBLIC_URL, REACT_APP_IMAGES_PATH } = process.env;
  const [namesArray, setNamesArray] = useState([]);
  const { name, type } = useParams();

  useEffect(() => {
    const source = axios.CancelToken.source();
    axios
      .get(
        `${REACT_APP_REST_URI}categories/${type.toUpperCase()}/${name.toUpperCase()}`,
        {
          cancelToken: source.token,
        }
      )
      .then((res) => {
        setNamesArray(res.data);
      })
      .catch(() => {
        setNamesArray([]);
      });
    return () => source.cancel();
  }, [setNamesArray, REACT_APP_REST_URI, name, type]);

  return (
    <div>
      <h1>NAGROBKI {name.toUpperCase()}</h1>
      <div className="gallery">
        {namesArray.map(
          (data) =>
            data && (
              <Link
                to={`/kategorie/${type.toLowerCase()}/${name.toLowerCase()}/${data.path.toLowerCase()}`}
                key={data.name}
              >
                <div>
                  <img
                    className="galleryImage"
                    src={`${PUBLIC_URL}${REACT_APP_IMAGES_PATH}${name.toUpperCase()}/${
                      data.path
                    }/${data.image}`}
                    alt={data.name}
                  />
                  <h2>{data.name.toUpperCase()}</h2>
                </div>
              </Link>
            )
        )}
      </div>
    </div>
  );
}

export default GalleryList;
