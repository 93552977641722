function Page404() {
  if (document.body.style !== "") document.body.style = "";
  return (
    <div className="page404">
      <h2>Not found</h2>
    </div>
  );
}

export default Page404;
